.show {
  display: block;
}

.hide {
  display: none;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 0.2s ease;
}

.modal {
  position: fixed;
  background: white;
  width: 80%;
  height: 85%;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 2px 4px 0 rgba(50, 50, 93, 0.1);
}

.modal .close {
  position: absolute;
  right: -1rem;
  top: -1rem;
  width: 2rem;
  height: 2rem;
  padding: 5px 0 6px 0.7px;
  margin: 0 auto;
  border-radius: 50%;
  box-shadow: 1px 1px 1px #0000003a;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.562);
}

.modal .header {
  padding: 30px;
  display: flex;
  justify-content: space-between;
}

.info-client {
  font: 16px 'Roboto', sans-serif;
  font-weight: bold;
  color: #666;
}

.info-client .name {
  font-size: 32px;
}

.modal .content {
  max-width: 95%;
  margin: 0 auto 20px;
  height: 500px;
  overflow-y: auto;
  padding: 0 15px 0 0;
  background: #f7f7f7;
  height: 48vh;
}

.modal .content h1 {
  font: 26px 'Roboto', sans-serif;
  font-weight: bold;
  color: #666;
  text-align: center;
  padding: 20px 0 20px;
}
.modal .content .hr {
  border-bottom: 5px solid #f1cb0b;
  margin-bottom: 20px;
}
.modal .content h2 {
  position: relative;
  font: 28px 'Roboto', sans-serif !important;
  height: 50px !important;
  font-weight: bold !important;
  color: #666 !important;
  z-index: 999;
  padding: 7px 30px 0 !important;
  display: inline-block;
  margin: 0 !important;
}
.modal .content h2:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0;
  background: #f1cb0b;
  height: 50px;
  z-index: -1;
  width: 90%;
  z-index: -1;
}
.modal .content h2:after {
  content: "";
  background-color: #f1cb0b;
  width: 82%;
  height: 50px;
  position: absolute;
  top: 0%;
  left: 15%;
  z-index: -1;
  transform: skew(20deg);
}
.modal .footer {
  max-width: 93%;
  margin: 0 auto 20px;
  padding: 10px 0 20px;
  text-align: right;
}

.modal .footer .print {
  border: none;
  margin: 8px 0px 0;
  height: 40px;
  background-color: #e7e6fa;
  font-weight: bold;
  color: #6159fd;
  text-transform: inherit;
  border-radius: 4px;
  font-size: 14px;
  transition: background-color 0.2s;
  display: flex;
  align-items: center;
  padding: 5px 30px 5px 25px;
  font-weight: bold;
  float: right;
}

.modal .footer .print svg {
  margin-right: 5px !important;
}

.modal .footer .print:hover,
.modal .footer .print:focus,
.modal .footer .print:active {
  background-color: #e7e6fa;
}

.modal .table {
  max-width: 70%;
  margin: 0 auto;
}
.modal .table.time-return {
  max-width: 100%;
  margin: 0 auto;
}
.modal .table.selo {
  position: relative;
}
.modal .table.selo .selo {
  position: absolute;
  left: -10%;
}
.modal .table.selo .selo img {
  width: 150px;
}

.modal .table table {
  margin: 20px 0 40px 0;
}
.modal .table table tr.title-tr th {
  padding: 5px 5px 20px;
}
.modal .table table th {
  padding: 5px 20px 5px 0;
}
.modal .table table td {
  padding: 5px;
  vertical-align: top;
}
.modal .info-geracao-estimada {
  margin: 20px;
}
.modal .info-geracao-estimada p {
  font-size: 10px;
  line-height: 14px;
}

.modal .total-economia {
  text-align: center;
}

.modal .total-economia h3 span {
  font-size: 28px;
  font-weight: bold;
}
.modal .total-economia h3.price {
  margin-top: 20px;
  font-weight: 300;
}

.modal .table.time-return h3 {
  margin-left: 20px;
}
.modal .table.validate h3 {
  font-size: 24px;
  font-weight: bold;
  margin: 0 0 40px 0;
}
.modal .footer .button-print button {
  margin-right: -10px;
}
